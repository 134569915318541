<template>
  <div class="card-custom align-items-center" v-if="ready">
    <b-row >
      <b-col md="8" offset-md="2">
        <b-card class="mt-3" no-body>
          <b-card-body>
            <b-row>
              <b-col md="3">
                <b-card-title>
                  {{user.prenom}} {{user.nom}}
                </b-card-title>
              </b-col>
              <b-col md="6" class="text-center">
                <b-button :variant="status.color" class="ml-1">{{status.titre}}</b-button>
              </b-col>
              <b-col md="3" class="text-end">
                <b-dropdown  no-caret class="me-1 lang-dropdown" v-if="$store.api.user.role === 1" >
                  <template #button-content>
                    <i class="fas fa-thermometer-half"></i>
                  </template>
                  <b-dropdown-item v-for="(s,i) in params.SinistreStatus" :key="i" :active="s.id === sinistre.status_id" @click="sinistre.status_id = s.id">{{s.titre}}</b-dropdown-item>
                </b-dropdown>
                <b-button variant="success" class="btn-icon" @click="save()">
                  <i class="fas fa-save" v-if="this.loader_save === false"></i>
                  <i class="fas fa-circle-notch fa-spin" v-else></i>
                </b-button>
              </b-col>
            </b-row>
            <hr class="my-2">
            <b-row class="mt-3">
              <p class="h5 text-primary" v-b-toggle.general>General</p>
              <b-collapse id="general" visible>
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Type de sinistre">
                      <select class="form-control" v-model.number="sinistre.type_id" disabled>
                        <option v-for="(t,i) in params.SinistreType" :key="i" :value="t.id">{{t.titre}}</option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="A indemniser">
                      <b-form-input v-model.number="sinistre.montant"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Date limite de paiement">
                      <b-form-input type="date" v-model.number="sinistre.montant"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Date de paiement">
                      <b-form-input type="date" v-model.number="sinistre.montant"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>
            <hr class="my-2">
            <b-row class="mt-3">
              <p class="h5 text-primary" v-b-toggle.echange_client>Echange avec le client</p>
              <b-collapse id="echange_client">
                <b-card :title="'Sinistre '+sinistre.id" id="chat">
                  <hr class="my-4">
                  <div id="card-messages">
                    <b-row v-for="(d,i) in data" :key="i" class="mt-2">
                      <b-col sm="6" v-if="d.agent === 'user'" class="p-3 text-md-end">
                        <small>{{$store.api.timestampToDate(d.date_create)}}</small>
                      </b-col>
                      <b-col sm="6">
                        <div class="p-3" :class="d.agent  === 'user'? 'is_user' : 'no_user'">
                          {{d.message}}
                        </div>
                        <div v-if="d.type === 'options'" class="p-1">
                          <b-button size="sm" v-for="(o,i) in d.options" :key="i" :variant="o.color" @click="auto(o,d.key)" class="me-1 mt-1">{{o.text}}</b-button>
                        </div>
                      </b-col>
                      <b-col sm="6" v-if="d.agent === 'bot'" class="p-3 text-left">
                        <small>{{$store.api.timestampToDate(d.date_create)}}</small>
                      </b-col>
                    </b-row>
                  </div>
                  <hr class="my-4">
                  <b-input-group>
                    <b-form-input v-model="tmpMessage" placeholder="Votre message !"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="outline-success" @click="answer()"><i class="fas fa-paper-plane"></i></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-card>
              </b-collapse>
            </b-row>
            <hr class="my-2">
            <b-row class="mt-3">
              <p class="h5 text-primary" v-b-toggle.documents>Documents</p>
              <b-collapse id="documents" visible>
                <b-row>
                  <b-col md="3" style="color:red" v-if="is_existe('etat_lieux_entree')">
                    <a :href="sinistre.data['etat_lieux_entree']" target="_blank">Etat lieux entree</a>
                  </b-col>
                  <b-col md="3" style="color:red" v-if="is_existe('etat_lieux_sortie')">
                    <a :href="sinistre.data['etat_lieux_sortie']" target="_blank">Etat lieux sortie</a>
                  </b-col>
                  <b-col md="3" style="color:red" v-if="is_existe('resiliation_bail')">
                    <a :href="sinistre.data['resiliation_bail']" target="_blank">Resiliation bail</a>
                  </b-col>
                  <b-col md="3" style="color:red" v-if="is_existe('devis_travaux')">
                    <a :href="sinistre.data['devis_travaux']" target="_blank">Devis travaux</a>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>
            <hr class="my-2">
            <b-row class="mt-3">
              <p class="h5 text-primary" v-b-toggle.commentaire>Commentaire</p>
              <b-collapse id="commentaire">
                <b-form-textarea v-model="sinistre.commentaire" rows="7" disabled id="commentaire_input"></b-form-textarea>
                <b-form-group label="Votre commentaire:">
                  <b-input-group>
                    <b-form-input v-model="commentaireTmp" @keyup.enter="addCommentaire()"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="success" @click="addCommentaire()">
                        <i class="fas fa-paper-plane"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-collapse>
            </b-row>
            <hr class="my-2">
            <b-row class="text-center mt-2" v-if="$store.api.user.role === 1">
              <b-col md="6">
                <b-card-text class="mt-2">
                  <strong>Reponse de prise en charge par MVP</strong>
                </b-card-text>
              </b-col>
              <b-col md="6">
                <b-button class="me-2" @click="sinistre.status_id = 5">Oui</b-button>
                <b-button class="me-2" @click="sinistre.status_id = 6">Non</b-button>
                <b-button class="me-2" @click="sinistre.status_id = 2">Incomplet</b-button>
              </b-col>
            </b-row>
            <b-row class="text-center mt-2" v-if="$store.api.user.role === 2">
              <b-col md="6">
                <b-card-text class="mt-2">
                  <strong>Reponse de prise en charge par Teletech</strong>
                </b-card-text>
              </b-col>
              <b-col md="6">
                <b-button class="me-2" @click="sinistre.status_id = 3">Oui</b-button>
                <b-button class="me-2" @click="sinistre.status_id = 6">Non</b-button>
                <b-button class="me-2" @click="sinistre.status_id = 1">Incomplet</b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data: () => ({
    ready: false,
    loader_save:false,
    commentaireTmp: null,
    params:{
      SinistreType:[],
      SinistreStatus:[]
    },
    data:[],
    user:{},
    sinistre:{}
  }),
  methods: {
    is_existe(key){
      if(this.sinistre.data[key] === undefined){
        return false;
      }else{
        return true;
      }
    },
    auto(data, key){
      var params = {
        sinistre_id: this.sinistre.id,
        txt:data.text,
        key:key,
        value:data.value
      }
      this.$store.api.ajax('/sinistre/answer', params, res => {
        if(res.status === true){
          this.sinistre = res.data.sinistre;
          this.data = res.data.chats;
          setTimeout(() => {
            this.scrollDown();
          },50);
        }
      });
    },
    answer(){
      var data = {
        text:this.tmpMessage,
        value:null
      }
      this.auto(data, null);
      this.tmpMessage = null;
    },
    scrollDown(){
      var objDiv = document.getElementById("card-messages");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    addCommentaire(){
      if(this.sinistre.commentaire === null){
        this.sinistre.commentaire = "";
      }
      var date = new Date();
      var day = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();
      var month = date.getMonth() < 10 ? '0'+date.getMonth() : date.getMonth();
      var year = date.getFullYear();
      var hour = date.getHours() < 10 ? "0"+date.getHours() : date.getHours();
      var minute = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();
      var txt = `${this.commentaireTmp} | par ${this.$store.api.user.prenom} ${this.$store.api.user.nom} le ${day}/${month}/${year} à ${hour}:${minute}`;
      this.sinistre.commentaire += txt;
      this.sinistre.commentaire += "\n";
      this.commentaireTmp = null;
      setTimeout(() => {
        var textarea = document.getElementById('commentaire_input');
        textarea.scrollTop = textarea.scrollHeight;
      },5)
    },
    save(){
      if(this.loader_save === true){
        return false;
      }
      this.loader_save = true;
      this.$store.api.ajax('/admin/sinistres/update', this.sinistre, res => {
        if(res.status === true){
          this.sinistre = res.data;
        }
        this.loader_save = false;
      })
    }
  },
  computed:{
    status(){
      var check = this.params.SinistreStatus.find(s => s.id === this.sinistre.status_id);
      if(check === undefined){
        return {
          'titre':'',
          'color':'secondary'
        };
      }
      return check
    },
    type(){
      var check = this.params.SinistreType.find(s => s.id === this.sinistre.type_id);
      if(check === undefined){
        return ''
      }
      return check.titre
    },
  },
  beforeMount() {
    this.$store.api.ajax('/admin/params', null, (res) => {
      if(res.status === true){
        this.params = res.data
      }
    })
  },
  mounted() {
    this.$store.api.ajax('/sinistre/admin/'+this.$route.params.id, null, (res) => {
      if(res.status === true){
        this.user = res.data.user;
        this.sinistre = res.data.sinistre;
        this.data = res.data.chats;
        this.ready = true;
        setTimeout(() => {
          this.scrollDown()
        },500)
      }
    })
  }  
}
</script>
<style media="screen">
@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
#chat .is_user {
  background:#3699FF;
  color: white;
  border-radius: 8px;
}
#chat .no_user {
  background:#a5a6af;
  color: white;
  border-radius: 8px;
}
#card-messages {
  height: 550px;
  overflow: scroll;
  padding: 15px;
}
.btn.dropdown-toggle {
  width: initial;
  height:initial;
}
</style>
